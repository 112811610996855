@import '~#/theme/variables/colors.scss';

.button {
  background: rgba(60, 60, 100, 0.5);
  border: 0;
  border-radius: 4px;
  color: $white;
  cursor: pointer;
  font-size: 16px;
  padding: 10px;
}

.button:focus {
  outline: 0;
}

.button:hover {
  background: rgba(100, 100, 140, 0.4);
}
