@import '~#/theme/zIndex.scss';

@font-face {
  font-family: Roboto;
  src: local('Roboto'), url('../../../static/fonts/Roboto-Regular.ttf') format('truetype');
}

.wrapper {
  background-color: #23282d;
  bottom: 0;
  color: #eee;
  font-family: Roboto;
  position: fixed;
  top: 0;
  z-index: $panel-z-index;
}

.trigger {
  color: white;
  cursor: pointer;
  position: fixed;
  top: 100px;
}
