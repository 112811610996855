@import '~#/elevate/theme/vw';

.navigation-arrow {
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity .2s ease-out;
  z-index: 1;

  // only focused, but not enabled, happens when scrolling to end
  &.focused {
    opacity: .5;

    & > .arrow-button {
      pointer-events: auto;
    }
  }

  &.enabled {
    opacity: 1;
    pointer-events: auto;
  }

  > .arrow-button {
    position: absolute;
  }

  &.left,
  &.right {
    & > .arrow-button {
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.right {
    left: auto;
    right: 0;

    & > .arrow-button {
      left: 0;
      right: auto;
    }
  }

  &.up,
  &.down {
    & > .arrow-button {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.up {
    & > .arrow-button {
      top: vw(60px);
    }
  }

  &.down {
    & > .arrow-button {
      bottom: vw(60px);
    }

    bottom: 0;
    top: auto;
  }
}

.icon {
  height: vw(22px);

  &.icon-right {
    height: vw(26px);

    path {
      transform: translate(vw(5px), vw(-3px));
    }
  }

  &.icon-left {
    path {
      transform: translate(vw(2px), vw(-3px));
    }
  }
}
