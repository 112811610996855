@import './commonStyles.scss';

.common {
  &,
  &:focus {
    outline: 0;
  }
}

.typeFill {
  background-image: linear-gradient(to bottom, #70ffe2, #3af2cd);
  border: solid 2px rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  font-size: 10px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  margin: 10px 10px 10px 0;
  text-align: center;
}

.typeEmptyThin,
.typeEmpty {
  border: solid 1px rgba(61, 255, 216, 0.7);
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  transition: linear background-color 0.5s;
  user-select: none;
}

.typeEmpty {
  border-radius: 8px;
  color: $link-color;
  font-weight: 600;
  padding: 16px 0;
}

.typeEmptyThin {
  border-radius: 4px;
  color: #eee;
  font-size: 12px;
  padding: 4px 10px;
}
