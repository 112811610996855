@import '~#/theme/variables/colors.scss';
@import '~#/theme/variables/ratios.scss';
@import '~#/theme/mixins/aspectRatio.scss';
@import '~#/theme/mixins/responsive.scss';

$hoverShadowColor: $white;
$categoryTextColor: $rgb-200;
$categoryBackgroundColor: rgba(10, 20, 30, 0.8);
$heroBackgroundColor: rgba(10, 20, 30, 0.8);
$assetMask: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(23, 23, 23, 0.6));
$itemHoverColor: rgb(61, 255, 216);
$itemHoverColorFill: rgba(61, 255, 216, 0.5);

.itemCard {
  background-color: $rgb-60;
  background-origin: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: $white;
  margin: 5px;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.nonEditor:hover {
    box-shadow: 0 0 7px 1px $hoverShadowColor;
  }

  &.editor:hover {
    .itemTextContainer::after {
      border: solid 2px $itemHoverColor;
      content: '';
      height: calc(100% - 4px);
      left: 0;
      position: absolute;
      top: 0;
      width: calc(100% - 4px);
    }
  }

  &.selected,
  &.selected.editor:hover {
    .itemTextContainer::after {
      border: solid 5px $itemHoverColorFill;
      content: '';
      height: calc(100% - 10px);
      left: 0;
      position: absolute;
      top: 0;
      width: calc(100% - 10px);
    }
  }
}

.itemCard.category {
  background-color: $categoryBackgroundColor;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero-vertical,
.hero-banner {
  @include aspect-ratio($wide-ratio);

  &.itemCard {
    background-color: $heroBackgroundColor;
    margin: 0;
  }

  .itemTextContainer {
    justify-content: center;
  }

  .itemTextContainer .itemText {
    font-size: 24px;
    line-height: 46px;
    padding: 25px;
    padding-bottom: 0;

    @include responsive(small-screens) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  .itemTextContainer .itemSubtitle {
    padding-left: 25px;
    padding-top: 10px;
  }
}

.itemTextContainer {
  background: $assetMask;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  left: 0;
  line-height: 18px;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
  position: absolute;
  top: 0;
}

.itemText {
  color: $white;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
  overflow: hidden;
  padding: 0;
  text-align: left;
  text-overflow: ellipsis;
  text-shadow: 0 1px 1px $black-50;

  @include responsive(small-screens) {
    font-size: 15px;
  }
}

.itemSubtitle {
  color: $rgb-200;
  font-size: 14px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include responsive(small-screens) {
    font-size: 12px;
  }
}

.category .itemText {
  background: none;
  color: $categoryTextColor;
  text-transform: uppercase;
}

.itemLink {
  height: 100%;
  padding-top: 100px;
  text-decoration: none;
  width: 100%;
}

.carousel {
  &.portrait {
    @include aspect-ratio($portrait-ratio);
  }

  &.wide {
    @include aspect-ratio($landscape-ratio);
  }

  &.square {
    @include aspect-ratio($square-ratio);
  }
}

.itemCard.movie,
.itemCard.tvshow {
  background-image: url('../../static/images/placeholder_movie.jpg');
}
