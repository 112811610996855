@import '~#/theme/base.scss';
@import '~#/theme/zIndex.scss';
@import '~#/theme/themes/dark/colors.scss';
@import '~#/theme/themes/light/colors.scss';

$title-size: 20px;

.logoTitle {
  display: inline-block;
  flex-grow: 1;
  font-size: $title-size;
  height: $title-size;
  line-height: $title-size;
  margin: auto;
  margin-left: 20px;
  margin-right: 20px;
}

.logo {
  width: 50px;
}

.logoLink {
  height: 50px;
  margin-bottom: auto;
  margin-top: auto;
  cursor: pointer;
}

.appBar {
  align-content: center;
  background-color: $rgb-30;
  display: flex;
  height: 56px;
  justify-content: left;
  padding-left: 30px;
  padding-right: 30px;
  z-index: $header-z-index;

  @include responsive(medium-screens) {
    height: 60px;
    min-height: 72px;
  }
}

.light {
  &.appBar {
    background-color: $light-theme-color-lategrey;
  }
}

.dark {
  &.appBar {
    background-color: $rgb-40;
    color: $dark-theme-color-bright;
  }
}
