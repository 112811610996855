.bottom {
  display: inline-block;
  margin-left: 10;
  margin-top: 7;
  width: 150;
}

.pushToProduction {
  position: absolute;
  right: 6px;
  top: 2px;
}
