@import './commonStyles';

$cross-size: 20px;

.cross {
  cursor: pointer;
  font-family: system-ui;
  font-weight: bold;
  font-size: $cross-size;
  line-height: $cross-size;
  position: absolute;
  right: 2rem;
  top: 1rem;
}

.divisor {
  height: 1px;
  width: 100%;
}

.newPageForm {
  display: flex;
  margin-top: 17px;
  width: 100%;
}

.newPageItem {
  flex-grow: 2;
  padding-right: 20px;
}

.newPageButton {
  margin-right: 0;
  margin-top: 25px;
  min-width: 150px;
  padding: 10px 0;
  width: 100%;
}

.newPageLabel {
  color: #fff;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  margin-bottom: 10px;
  width: 144px;
}

.wrapper {
  font-family: Roboto;
  padding: 55px 78px;
}

.modalSectionHeader {
  color: #eee;
  font-size: 16px;
  font-weight: 900;
  height: 19px;
  margin-top: 26px;
}

.createdPageRow {
  display: flex;
  height: 40px;
}

.createdPageRowItem {
  flex-grow: 1;
  position: relative;
}

.assignDestinationRow {
  display: flex;
  height: 40px;
}

.assignDestingationRowItem {
  flex-grow: 1;
}

.goToPageLink {
  color: $link-color;
  cursor: pointer;
  line-height: 40px;
  width: 10em;
}

/* ENDLESS ROTATE */
.rotate {
  animation: rotate 1.5s linear infinite;
}
@keyframes rotate {
  to { transform: rotate(360deg); }
}

