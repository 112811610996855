@import '~#/theme/base.scss';

.viewport {
  height: 100%;
  width: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.content {
  display: flex;
  flex: 1;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
}

.pageContent,
.pageTitle {
  padding: 20px;
  padding-left: 40px;
}

.pageTitle {
  background-color: $page-title-background;
  color: $rgb-10;
}
