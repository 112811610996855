@import '~#/elevate/components/Item/AssetItem/assetItem';

.background {
  background-position: center;
  background-size: contain;

  .content-container {
    .title {
      font-size: 20px;
    }
  }
}
