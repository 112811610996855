@import '~#/elevate/theme/main';

.item {
  float: left;
  line-height: vw(48px);
  margin-left: vw(104px);
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;

  @include browser($legacy-devices) {
    width: vw(80px);
  }

  button {
    background: rgba(60, 60, 100, .5);
    border: 0;
    border-radius: vw(4px);
    color: var(--coloronbackground);
    cursor: pointer;
    font-size: 12px;
    overflow: hidden;
    padding: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    a {
      text-decoration: none;
    }
  }

  img {
    border-radius: 50%;
    height: vw(69px);
    object-fit: cover;
    padding: 0;
    width: vw(69px);
  }
}

.secondary {
  margin-left: vw(35px);

  @include browser($legacy-devices) {
    margin-left: vw(17.5px) !important;
    margin-right: vw(17.5px) !important;
  }

  svg {
    path {
      fill: var(--coloronbackground);
    }
  }
}

.item-focused {
  svg {
    path {
      fill: var(--accent);
    }
  }

  .profile-picture {
    animation: highlight .3s forwards;
    border: vw(4px) solid var(--accent);
  }
}

.item-current {
  a {
    color: var(--accent);
  }
}

.primary {
  &.item-focused {
    button {
      border-bottom: vw(3px) solid var(--accent);

      @include browser($legacy-devices) {

        border-bottom-color: var(--accent);
        border-bottom-style: solid;
        border-bottom-width: vw(3px);
      }
    }
  }
}

.icon {
  height: vw(45px);
}
