@import '~#/elevate/theme/partial/layout';

.outer-container {
  overflow: hidden;
  width: 100%;
}

.inner-container {

  @include horizontal-layout;
}
