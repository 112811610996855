.wrapper {
  color: lightgrey;
  font-size: 12px;
}

.description {
  line-height: 1.1em;
  padding: 8px;
}

.mmContainer {
  align-items: center;
  background-size: cover;
  border: 3px solid transparent;
  display: grid;
  grid-template-columns: 38px 1fr 48px;
  justify-items: center;
}

.mmIndex {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  height: 24px;
  line-height: 2.1em;
  text-align: center;
  width: 24px;
}

.mmComponent {
  align-items: center;
  background: lightgray;
  border-radius: 8px;
  color: gray;
  display: grid;
  font-size: 13px;
  grid-column: 2;
  grid-template-columns: 8px 48px 8px 1fr;
  height: 48px;
  justify-content: center;
  margin: 3px;
  text-transform: capitalize;
  width: 100%;
}

.mmComponentImg {
  display: inline-block;
  grid-column: 2;
  height: 48px;
  width: 48px;
}

.mmComponentDetails {
  display: inline-block;
  display: flex;
  flex-direction: column;
  grid-column: 4;
  height: 48px;
  justify-content: center;
  line-height: 1.2em;
  overflow: hidden;
  width: 100%;

  &,
  b,
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  p {
    font-size: 11px;
    margin: 0;
  }
}

.handle {
  grid-column: 3;
  height: 26px;
  width: 26px;
}

.selected {
  background-color: rgba(20, 85, 72, 0.6);
  border: 3px solid rgba(61, 255, 216, 0.5);

  .mmIndex {
    background-color: #d8d8d8;
    color: #333;
  }
}
