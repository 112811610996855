.spinner {
  height: 49px;
  margin: auto;
  text-align: center;
  width: 78px;
}

.spinner span {
  animation: bubbly 0.4s infinite alternate;
  background: rgb(0, 0, 0);
  border-radius: 49px;
  display: inline-block;
  height: 10px;
  margin: 3px;
  vertical-align: middle;
  width: 10px;
}

.spinner span.b1 {
  animation-delay: 0s;
}

.spinner span.b2 {
  animation-delay: 0.13s;
}

.spinner span.b3 {
  animation-delay: 0.26s;
}

@keyframes bubbly {
  0% {
    background-color: rgb(60, 60, 60);
    height: 6px;
    width: 6px;
  }

  100% {
    background-color: rgb(205, 205, 205);
    height: 8px;
    width: 8px;
  }
}
