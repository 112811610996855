@import '~#/elevate/theme/main';

.query-text {

  @include title-font-size;

  color: $main-highlight-color;
}

.outer-container {
  border-radius: 2px;
  padding: vw(40px) 0;
  transform: none;
}

.inner-container {
  width: 100%;

  &.ltr {
    padding: 0 0 0 1%;
  }
}

.container-title {
  color: var(--coloronbackground);
  font-size: vw(33px);
  line-height: normal;
  padding: 0 vw(87px) vw(5px);
}


.hero-vertical,
.elevate-hero-banner {
  &.outer-container {
    margin: auto;
    padding-bottom: 45%;
    padding-top: 0;
    position: relative;
    width: 100%;
    margin-bottom: 3px;
  }

  .container-title {
    margin-top: 0;
    display: none;
  }

  .inner-container {
    bottom: 0;
    display: block;
    left: 0;
    padding: 0;
    padding-bottom: vw(30px);
    position: absolute;
    right: 0;
    top: 0;
  }
}
