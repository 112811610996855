@import '~#/theme/zIndex.scss';

.wrapper {
  position: relative;
}

.arrowWrapper {
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: $container-wrapper-arrow-z-index;
}

$arrowSide: 30px;

.arrowContent {
  background-image: linear-gradient(to bottom, #70ffe2, #3af2cd);
  border: solid 2px rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  display: inline-block;
  height: $arrowSide;
  width: $arrowSide;
}

.trash {
  background-color: rgba(61, 255, 216, 0.4);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 21px 21px;
  border-radius: 0 0 0 3px;
  cursor: pointer;
  height: 30px;
  position: absolute;
  right: 0;
  width: 30px;
  z-index: $trash-z-index;
}
