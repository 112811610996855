@import '~#/elevate/theme/base';

.hero-skeleton {
  background-color: var(--alternativebackground);
  height: 75vh;
  padding: 5vh 1vw 0;
  width: 98vw;
}

.content-carousel {
  padding-top: 20vh;

  &.ltr {
    padding-left: 4vw;
  }

  &.rtl {
    padding-right: 4vw;
  }

  .banner-content {
    height: 7vh;
    margin-bottom: 3vh;
    width: 50vw;
  }
}

.button {
  border-radius: vw(11.83px);
  display: inline-block;
  height: vw(66.23px);
  margin-right: vw(15.36px);
  width: vw(288px);
}

.grid-skeleton {
  padding: 0 1vw;
}

.title-skeleton {
  background-color: rgb(143, 143, 143);
  display: inline-block;
  height: 5vh;
  margin: vw(19.2px) 0 vw(19.2px) vw(80.64px);
  width: 10vw;
}

.title-skeleton-low-margin {
  background-color: rgb(143, 143, 143);
  display: inline-block;
  height: 5vh;
  margin: vw(19.2px) 0 vw(19.2px) vw(9px);
  width: 10vw;
}

.description-container {
  height: 60vh;
  padding: vw(254.97px) 0 0 vw(87.93px);

  &.ltr {
    margin-left: 5vw;
  }

  &.rtl {
    margin-right: 5vw;
  }

  .portrait-detail-skeleton {
    height: 54vh;
    width: 20vw;
  }

  .landscape-detail-skeleton {
    border-radius: vw(11.83px);
    height: 49.1vh;
    width: 32.7vh;
  }

  .landscape-description-skeleton {
    display: inline-block;
    height: 41.23vh;
    margin-left: vw(33.6px);
    position: fixed;
    width: vw(1128.95px);
  }

  .text-skeleton {
    height: 60%;
    margin-bottom: 5vh;
    width: 100%;
  }

  .portrait-description-skeleton {
    display: inline-block;
    height: 70vh;
    width: 65vw;
  }
}

.item-skeleton {
  display: inline-block;
  margin: 0 vw(9.6px) vw(10.8px);

  &.rtl {
    margin-left: vw(19.2px);
    margin-right: 0;
  }
}

.poster-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.poster-list-content-left {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.show-container {
  left: vw(8.3px);
  position: absolute;
  top: vw(866px);

  .seasons-container {
    display: flex;
    margin: 0 0 vw(57.6px) vw(92.16px);

    .text {
      height: vw(54px);
      margin-right: vw(28.8px);
      width: vw(124.8px);
    }

    .seasons-list {
      display: flex;

      .season-item {
        border-radius: vw(40px);
        height: vw(54px);
        margin-right: .5vw;
        width: vw(57.6px);
      }
    }
  }

  .show-poster-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 0 0 vw(80.64px);

    .landscape-skeleton {
      border-radius: vw(10.78px);
      height: vw(324px);
      width: vw(431.04px);
    }
  }
}

.portrait-skeleton {
  height: 45vh;
  width: 18.5vw;
}

.small-skeleton {
  height: 25vh;
  width: 12vw;
}

.landscape-skeleton {
  border-radius: vw(10.78px);
  height: vw(324px);
  width: vw(328.31px);
}

.spacing-grid {
  margin-top: 10vh;
}

.search-skeleton {
  height: 94%;
  width: 100%;

  .search-keyboard {
    display: inline-block;
    height: vw(490px);
    margin: vw(100px) vw(20px);
    width: vw(645px);
  }

  .search-trends {
    display: inline-block;
    margin-left: 40px;
    vertical-align: top;
    width: 56%;

    &.rtl {
      margin-left: 0;
      margin-right: 40px;
    }
  }

  .search-suggestion {
    border-radius: vw(25px);
    height: 12vh;
    margin-bottom: 2vh;
    margin-right: 8vw;
    width: 100%;

    &.rtl {
      margin-left: 8vw;
      margin-right: 0;
    }
  }
}

.base-color {
  background-color: rgba(202, 202, 202, .2);
}
