@import '~#/elevate/theme/main';

.hero-vertical,
.elevate-hero-banner {
  background-color: transparent;
}

.elevate-hero-banner-container {
  ul {
    margin: 5% 0;

    li {
      button {
        border-radius: 50%;
        color: red !important;
        filter: brightness(1.2);
        height: vw(25px);
        width: vw(25px);
      }

      button::before {
        color: #ebebeb !important;
        font-size: vw(18px);
        opacity: .5;
      }
    }
  }
}
