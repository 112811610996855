@import '~#/theme/themes/light/colors.scss';
@import '~#/theme/variables/colors.scss';

body {
  background-color: $rgb-30;
  color: $white;
}

.root {
  height: 100%;
  margin: 0 auto;
  width: 100%;
}

:global(.link) {
  text-decoration: none;
}

:global(.link:hover) {
  text-decoration: underline;
}

.dark {
  &.root {
    background-color: $rgb-30;
    color: $white;
  }

  :global(.link) {
    color: $rgb-150;
  }
}

.light {
  &.root {
    background-color: $light-theme-color-content;
    color: $rgb-40;
  }

  :global(.link) {
    color: $rgb-150;
  }
}
