@import '~#/theme/zIndex.scss';

.overlay {
  background-color: transparent;
  bottom: 0;
  left: 0;
  padding: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $modal-z-index;
}

.modal {
  background: #23282d;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: rgba(255, 255, 255, 0.2) -1px 0 0 0 inset;
  height: 315px;
  left: 0;
  outline: none;
  pointer-events: all;
  position: absolute;
}
