@import '~#/theme/zIndex.scss';
@import '~#/theme/base.scss';
$menu-inactive: $rgb-160;

.toggle {
  @include responsive(medium-screens) {
    display: block;
    position: absolute;
    right: 26px;
    top: 14px;
  }

  display: none;
}

.toggle-mobile-preview {
  display: block;
  position: absolute;
  right: 26px;
  top: 14px;
}

.container {
  color: $menu-inactive;
  margin-bottom: auto;
  margin-left: auto;
  margin-top: auto;
  padding-top: 0;
  position: relative;
  z-index: $header-menu-z-index;

  @include responsive(medium-screens) {
    padding-top: 50px;
    width: 100%;
  }
}

.light {
  &.expanded {
    .toggle {
      @include responsive(medium-screens) {
        top: 24px;
      }
    }
  }
}

.collapsible {
  display: none;

  &.expanded {
    display: block;
  }

  > div:first-child {
    background-color: $rgb-60;
    box-shadow: 0 5px 9px 0 $black-40;
    height: auto;
    padding: 0 30px 12px;
    position: absolute;
    right: 10px;
    top: 50px;
    min-width: 200px;
    width: auto;
  }
}
