@import '~#/theme/variables/colors.scss';

.titleContainer {
  background-color: $rgb-240;
  color: $rgb-10;
  padding: 20px;
  padding-left: 40px;
}

.dark {
  &.titleContainer {
    background-color: $rgb-0;
    color: $white;
  }
}

.light {
  &.titleContainer {
    background-color: $rgb-250;
    border-bottom: 1px solid $lightgrey;
    color: $rgb-130;
  }
}
