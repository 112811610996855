@import './colors';

@import './breakpoints';

@import './responsiveMixin';

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap');

$topbar-height: 80px;
$legacy-devices: 'lg-webos_2015' 'samsung-tizen_2015' 'samsung-tizen_2016';

body {
  color: $dark;
  font: 400 15px/22px Roboto, sans-serif;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

a {
  color: $main-text-color;
}
