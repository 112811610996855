@import '~#/theme/variables/colors.scss';
@import '~#/theme/zIndex.scss';

.toggleDebug {
  height: 50px;
  position: fixed;
  text-align: right;
  top: 120px;
  width: 100%;
  z-index: $toggle-debug-z-index;
}

.page {
  padding: 50px;
}

.pageContainers {
  padding-top: 50px;
}

.container {
  background-color: $rgb-200;
  border: 1px solid $lightgrey;
  border-radius: 2px;
  color: $rgb-0;
  margin-bottom: 10px;
}

.containerTitle {
  background-color: $rgb-230;
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
}

.containerProps {
  padding: 50px;
}

.itemProps {
  padding: 10;
}

.item {
  background-color: $rgb-250;
  border: 1px solid $lightgrey;
  margin-bottom: 10px;
}

.itemTitle {
  background-color: $rgb-230;
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
}

.propRow {
  margin-bottom: 10px;
  padding: 4px;
}

.propKey {
  font-size: 16px;
  font-weight: bold;
  margin-right: 40px;
}

.propValue {
  display: inline;
}
