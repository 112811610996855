@import '~@accedo/vdkweb-player/lib/components/Player/index.scss';
@import '~#/theme/base.scss';

$player-section-width: 100%;
$player-section-max-width: 1200px;

.metadataContainer {
  display: flex;
  justify-content: center;
  padding: 40px;

  @include responsive(small-screens) {
    align-items: center;
    flex-direction: column;
  }
}

.movieCoverContainer {
  margin-bottom: 20px;
  margin-right: 20px;
}

.movieCover {
  height: 374px;
  width: 250px;
}

.movieTitle {
  background-color: rgba(240, 240, 240, 1);
  padding: 20px;
  padding-left: 40px;
}

.movieDescription {
  max-width: 700px;

  div {
    margin-bottom: 10px;
  }
}

.playerSection {
  background-color: rgba(240, 240, 240, 0.3);
}

.playerOuterContainer {
  margin: auto;
  max-width: $player-section-max-width;
  padding-bottom: 10px;
  padding-top: 10px;

  @include responsive(medium-screens) {
    padding: 0;
  }
}

.playerInnerContainer {
  margin: auto;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
}

.playerContainer {
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.playerControls {
  background-image:
    linear-gradient(
      to bottom,
      $black-0,
      $black-60 55%,
      $player-gradient
    );
  bottom: 0;
  display: flex;
  height: 82px;
  max-width: inherit;
  padding: 10px;
  position: absolute;
  width: $player-section-width;

  &.fullScreen {
    bottom: 0;
  }
}

.playPauseContainer {
  margin-left: 10px;
  margin-right: 10px;
}

.fullScreenContainer {
  margin-right: 10px;
}

.video,
.video video {
  height: 100% !important;
  max-height: inherit;
  max-width: inherit;
  padding-top: 0 !important;
  width: 100%;
}

.error {
  color: $red;
}

:global(.video-js.vjs-fluid) {
  height: 100%;
  padding-top: 0;
  position: absolute;
}

:global(.video-js) {
  outline: none;
}
