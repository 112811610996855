@import './commonStyles.scss';
@import '~#/theme/variables/colors.scss';

.compContainer {
  display: inline-block;
  position: relative;
}

.comp {
  background-color: rgba(255, 255, 255, 0.15);
  border: solid 1px rgba(0, 0, 0, 0);
  border-radius: 4px;
  color: #eee;
  font-family: Roboto;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  height: $input-height;
  letter-spacing: normal;
  line-height: normal;
  padding: 8px;
  width: 100%;

  &:focus {
    border: solid 1px $link-color;
    caret-color: $link-color;
    outline: none;
  }
}

.comp[type=number] {
  min-width: 50px;
  position: relative;
  width: 20%;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ~ span {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 0;

    > div {
      height: $input-height*0.5;
      position: relative;

      &:first-child img {
        border-bottom-left-radius: 4px;
        transform: rotate(180deg);
      }

      &:nth-child(2) img {
        border-bottom-right-radius: 4px;
      }

      img {
        border: solid 1px rgba(255, 255, 255, 0.2);
      }
    }

    button {
      padding: 0;
    }

    img {
      background-color: $background-color;
      height: $input-height*0.5;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.comp:read-only {
    opacity: 0.7
}

.comp:read-only:hover {
    opacity: 0.3;
}

