@import '~#/theme/variables/colors.scss';

:global(.slick-dots) {
  li {
    button {
      color: $black-0;

      &::before {
        opacity: 0.25;
      }

      &:focus::before {
        opacity: 0.25;
      }
    }

    &:global(.slick-active) {
      button {
        &::before {
          opacity: 1;
        }
      }
    }
  }
}

:global(.slick-next::before) {
  color: $white !important;
}

:global(.slick-prev::before) {
  color: $white !important;
}

.dark {
  :global(.slick-arrow::before) {
    color: $white;
  }

  :global(.slick-dots) {
    li {
      button {
        &::before {
          color: $white;
        }
      }

      &:global(.slick-active) {
        button {
          &::before {
            color: $white;
          }
        }
      }
    }
  }
}

.light {
  :global(.slick-arrow::before) {
    color: $rgb-0;
  }

  :global(.slick-dots) {
    li {
      button {
        &::before {
          color: $rgb-0;
        }
      }

      &:global(.slick-active) {
        button {
          &::before {
            color: $rgb-0;
          }
        }
      }
    }
  }
}
