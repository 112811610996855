@import '~#/theme/base.scss';

@font-face {
  font-family: Roboto;
  src: local('Roboto'), url('../../../static/fonts/Roboto-Regular.ttf') format('truetype');
}

.leftPanel {
  color: lightgrey;
  font-family: Roboto;
  font-size: 12px;
}

.description {
  line-height: 1.1em;
  padding: 8px;
}

.componentPanel {
  display: flex;
  flex-wrap: wrap;

  > div {
    margin: 6px 5px;
    width: 28%;

    @include responsive(medium-large-screens) {
      width: 40%;
    }

    @include responsive(medium-screens) {
      width: 50%;
    }
    max-width: 10em;
  }
}

.componentContainer {
  align-items: center;
  border-radius: 10px;
  color: gray;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  height: 80px;
  justify-content: center;
  text-transform: capitalize;
  user-select: none;
  width: 100%;
  word-break: break-word;

  div {
    font-weight: 600;
    line-height: normal;
    text-align: center;
  }
}

.componentImg {
  background-repeat: no-repeat;
  height: 48px;
  width: 48px;
}
