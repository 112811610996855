@import '~#/elevate/theme/main';

$details-text-color: $rgb-235;
$itemHoverColorFill: rgba(61, 255, 216, 0.5);


.background {
  &:not(.selected) {
    &:hover {
      &.container-selected::after {
        border: solid 3px $itemHoverColorFill;
        content: '';
        height: calc(100% - 6px);
        left: 0;
        position: absolute;
        top: 0;
        width: calc(100% - 6px);
      }
    }
  }

  &.selected::after {
      border: solid 5px $itemHoverColorFill;
      content: '';
      height: calc(100% - 10px);
      left: 0;
      position: absolute;
      top: 0;
      width: calc(100% - 10px);
  }

  @include bottom-align-container;

  background-repeat: no-repeat;
  background-size: cover;
  border-radius: vw(15px);
  height: 100%;
  overflow: hidden;

  @include browser($legacy-devices) {
    border-radius: 12px !important;
  }

  img {
    object-fit: cover;
  }

  .click-area {
    background-color: transparent;
    border: vw(4px) solid transparent;
    border-radius: vw(15px);
    box-sizing: border-box;
    height: 100%;
    position: absolute;
    width: 100%;
  }

  &.background-focused {
    box-shadow: 0 0 vw(10px) var(--accent);
  }

  &.background-focused .click-area {
    position: absolute;
    top: 0;
  }

  .view-all {
    font-size: 26px;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 35%;
    width: 100%;
  }

  &.view-all-background {
    background-color: #303030;
  }

  .content-container {
    background-color: $grey-55;
    padding: 0;
    position: absolute;
    width: 100%;
  }

  .title {
    background-color: $grey-55;
    border-radius: 0 0 vw(15px) vw(15px);
    color: var(--coloronbackground);
    font-size: vw(23px);
    font-weight: 700;
    line-height: vw(32px);
    overflow: hidden;
    text-overflow: ellipsis;
    text-shadow: $text-shadow;
    white-space: nowrap;

    &.title-default {
      padding: vw(22px) vw(10px) vw(10px) vw(20px);
    }

    &.title-continue-watching {
      padding: vw(26.88px) vw(10px) vw(10px) vw(20px);
    }

    &.title-focused {
      background-color: var(--accent);
    }
  }
}
