@import '~#/theme/variables/colors.scss';
@import '~#/theme/themes/light/colors.scss';
@import '~#/theme/themes/dark/colors.scss';

.queryText {
  color: $main-highlight-color;
  font-size: 18px;
}

.outerContainer {
  border-radius: 2px;
  padding-top: 20px;
  transform: none;
}

.innerContainer {
  padding: 10px 30px 30px;
}

.containerTitle {
  border-left: 4px $main-highlight-color inset;
  color: $main-text-color;
  font-size: 16px;
  margin-left: 40px;
  padding: 0 8px;
}

.hero-banner,
.hero-vertical {
  &.outerContainer {
    margin: auto;
    padding-top: 0;
    position: relative;
    width: 100%;
  }

  .containerTitle {
    display: none;
  }
}

.hero-banner .innerContainer {
  bottom: 0;
  display: block;
  left: 0;
  padding: 0;
  padding-bottom: 30px;
  right: 0;
  top: 0;
}

.dark {
  .containerTitle {
    border-left: solid 4px $aquamarine;
    color: $dark-theme-color-bright;
  }
}

.light {
  .containerTitle {
    border-left: solid 4px $light-theme-color-lategrey;
    color: $light-theme-color-dark;
  }
}
