@import '~#/elevate/theme/main';

$title-size: vw(35px);

.logo-title {
  display: table-cell;
  font-size: $title-size;
  height: $title-size;
  padding-left: vw(20px);
  position: relative;
  vertical-align: middle;
  white-space: nowrap;
}

.logo {
  width: vw(50px);
}

.logo-link {
  height: vw(50px);
  margin-bottom: auto;
  margin-top: auto;
}

.user-control {

  @include responsive(medium-screens) {
    right: vw(96px);
    top: vw(26px);
  }

  position: absolute;
  right: vw(40px);
}

.container {

  @include display-table;

  box-sizing: border-box;
  padding: 0 $header-base;
  width: 100%;
  z-index: $header-container-z-index;

  &.fullscreen {
    position: absolute;
  }

  // for logo
  img {
    vertical-align: middle;
  }

  button {

    @include default-button;

    @include subtitle-font-size;

    position: relative;
    text-transform: uppercase;
  }

  :global(.triState) button {
    &::before {

      @include pseudo;

      border-bottom: solid-border();
      height: 70%;
      width: size-without-border(10px);
    }

    &.button-focused::before {
      border-bottom: solid-border(var(--accent));
    }
  }
}

.menu {
  height: vw(40px);
  margin-top: vw(3px);
  display: table-cell;
  vertical-align: middle;
}

.main-menu {
  display: inline-block;
  max-width: vw(1200px);
  overflow: hidden;

  @include browser($legacy-devices) {
    width: vw(1200px);
  }
}

@keyframes highlight {
  from {
    filter: brightness(1);
  }

  to {
    filter: brightness(1.2);
  }
}

.sec-menu {
  align-items: center;
  display: flex;
  height: vw(40px);
  margin-top: vw(18px);
  max-width: vw(368px);
  padding: vw(10px);
  text-align: right;

  @include browser($legacy-devices) {
    width: vw(368px);
  }

  &.ltr {
    float: right;
  }

  &.rtl {
    float: left;
  }

  .button-focused .profile-picture {
    animation: highlight .3s forwards;
  }

  ul li {
    button {
      padding: vw(10px) vw(10px) 0 1vw;
    }
  }
}

@media only screen and (max-width: 1700px) {
  .sec-menu {
    ul li {
      button {
        padding: vw(10px) 0;
      }
    }
  }
}

.title {
  bottom: 20px;
  display: inline-block;
  font-size: $title-size;
  height: 19px;
  margin-left: 30px;
  padding: 10px;
  position: relative;
  text-transform: uppercase;
}
