@import '../../base.scss';
@import '~@accedo/vdkweb-ui/lib/components/Menu/menu.scss';

$active-color: $aquamarine;

.menu {
  box-sizing: border-box;
}

.menu.collapsible {
  @include responsive(medium-screens) {
    display: none;
    margin-left: 0;
    text-align: left;
  }

  & .menuItem {
    @include responsive(medium-screens) {
      display: flex;
      height: 45px;
      margin-bottom: 5px;
    }
  }

  & .subItemsContainer {
    @include responsive(medium-screens) {
      background: none;
      display: block;
      position: relative;
      top: auto;
      width: 100%;
    }
  }
}

.menu.expanded {
  @include responsive(medium-screens) {
    background-color: $dark-grey;
    box-shadow: 0 5px 9px 0 $black-40;
    display: block;
    height: auto;
    left: 0;
    margin-top: 0 !important;
    padding-bottom: 12px;
    padding-left: 30px;
    padding-right: 30px;
    position: absolute !important;
    top: 70px !important;
  }
}

.menuItem {
  background-color: $transparent !important;
  border-radius: 0 !important;
  color: $main-text-color;
  font-size: 24px;
  height: 38px;
  padding: 6px 10px;

  @include responsive(medium-screens) {
    padding: 10px;
    padding-top: 15px;
  }

  &:hover {
    background-color: $transparent;
    border-bottom: 3px solid $rgb-100;

    @include responsive(medium-screens) {
      background-color: $dark-grey-80;
      border: none;
    }
  }
}

.menuItemActive,
.menuItemActive:hover {
  border-bottom: 3px solid $aquamarine;
}

.menuSubItem {
  background-color: $rgb-250;
  color: $dark-grey;

  @include responsive(medium-screens) {
    background-color: $transparent;
    margin-bottom: 5px;
    padding: 6px 0;
  }

  &:hover {
    background-color: $rgb-240;
    color: $active-color;

    span {
      color: $black-90;
    }
  }

  span {
    color: $dark-grey;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.33;
    text-align: left;

    @include responsive(medium-screens) {
      color: $rgb-200;
      padding-left: 20px;
    }
  }
}

.subItemsContainer {
  border-radius: 2px;
  box-shadow: 0 11px 9px 1px $black-30;
  padding: 0;
  top: 12px;

  @include responsive(medium-screens) {
    box-shadow: none;
    left: 0;
    padding-left: 8px;
  }
}

.menuItem:hover,
.menuItem:hover .menuItemText {
  background: $transparent !important;
  color: $active-color;
}

.menuItemText {
  color: $main-text-color;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.2px;

  @include responsive(medium-screens) {
    height: 20px;
    line-height: 20px;
    margin-bottom: auto;
    margin-top: auto;
  }
}

.menuItemContainer {
  @include responsive(medium-screens) {
    display: block;
  }
}

.menuItem,
.menuSubItem {
  @include responsive(medium-screens) {
    background: $transparent;
    display: flex;
    padding: 0;
    padding-left: 10px;
    position: relative;
  }
}

.menuItem:hover .menuItemSubText {
  opacity: 1;
}

.menuItem .menuItemIcon {
  @include responsive(medium-screens) {
    margin-bottom: auto;
    margin-top: auto;
  }
  height: 14px;
}

