@import '~#/theme/zIndex.scss';

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  padding: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $modal-z-index;
}
