@import '../../base.scss';
@import '~@accedo/vdkweb-ui/lib/components/Menu/menu.scss';
@import './colors.scss';

.menu {
  background-color: $light-theme-color-lategrey;
}

.menuSubItem {
  background-color: rgba(100, 100, 100, 0.8);

  @include responsive(medium-screens) {
    background: none;
  }
}

.menuSubItem:hover {
  @media screen and (min-width: $layout-breakpoint-sm-tablet) {
    background-color: $light-theme-color-lategrey-90;
  }
}

.menuSubItem span {
  color: $white;
}

.menuSubItem:hover span {
  color: $white;
}

.subItemsContainer {
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.5);
  padding: 0;
  top: 18px;

  @include responsive(medium-screens) {
    box-shadow: none;
  }
}
