@import '~#/theme/zIndex.scss';

@font-face {
  font-family: Roboto;
  src: local('Roboto'), url('../../../../static/fonts/Roboto-Regular.ttf') format('truetype');
}

.wrapper {
  background-image: linear-gradient(to bottom, #39403f, #1f2524);
  box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.2);
  color: white;
  display: table-cell;
  font-family: Roboto;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  vertical-align: middle;
  z-index: $horizontal-bar-z-index;
}

.footer {
  bottom: 0;
}

.header {
  top: 0;
}
