@import '../commonStyles.scss';

.itemContainer {
  align-items: center;
  background-image: linear-gradient(to bottom, #e6e6e6, #ccc);
  border-radius: 2px;
  color: #333;
  display: grid;
  font-size: 12px;
  grid-template-columns: 3px 20px 3px auto 5px 120px 1fr;
  justify-content: center;
  margin-bottom: 2px;
  position: relative;
  width: 100%;

  &:hover {
    background-image: linear-gradient(to bottom, $link-color-30, $link-color-30), linear-gradient(to bottom, #e6e6e6, #ccc);
  }
}

.item {
  &:first-child {
    text-align: center;
    width: 20px;
  }
}

.itemsTitle {
  color: #9d9d9d;
  font-size: 13px;
  margin-left: 16px;
}

.itemsList {
  margin-top: 12px;
}

.propTitle {
  float: left;
  font-size: 13px;
  height: $input-height;
  line-height: $input-height;
  min-width: 30%;
}

.propContent,
.propContentRight {
  font-size: 13px;
}

.propContentRight {
  margin-left: 30%;
}

.itemActions {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  right: 16px;
  width: 80px;
  grid-column: 9/auto;
}

.itemActions span {
  margin-right: 8px;
}

.itemInfo {
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 8px;
}

.parentEntryFieldLink {
  color: $link-color;
  cursor: pointer;
  line-height: 26px;
}

.description {
  background-color: rgba(255, 255, 255, 0.15);
  border: solid 1px rgba(0, 0, 0, 0);
  border-radius: 4px;
  color: #eee;
  font-family: Roboto;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 20px;
  padding: 8px;
  width: 100%;
}

.description:read-only {
    opacity: 0.7
}

.description:read-only:hover {
    opacity: 0.3;
}

$cross-size: 20px;

.cross {
  cursor: pointer;
  font-family: system-ui;
  font-weight: bold;
  font-size: $cross-size;
  line-height: $cross-size;
  position: absolute;
  right: 2rem;
  top: 1rem;
}
