@import '~#/elevate/theme/main';

$title-size: vw(35px);

.logo-title {
  display: inline-block;
  flex-grow: 1;
  font-size: $title-size;
  height: $title-size;
  line-height: $title-size;
  line-height: vw(102px);
  padding-left: vw(20px);
  position: absolute;
  top: 0;
  vertical-align: middle;
  white-space: nowrap;
}

.logo-title-local {
  display: table-cell;
  font-size: $title-size;
  padding-left: vw(20px);
  vertical-align: middle;
  white-space: nowrap;
}

.logo {
  width: vw(50px);
}

.logo-link {
  height: vw(50px);
  margin-bottom: auto;
  margin-top: auto;
}

.user-control {

  @include responsive(medium-screens) {
    right: vw(96px);
    top: vw(26px);
  }

  position: absolute;
  right: vw(40px);
}

.container {
  display: flex;

  box-sizing: border-box;
  color: var(--coloronbackground);
  padding: 0 $header-base;
  padding: 2.5rem !important;
  position: absolute;
  visibility: hidden;
  width: 100%;
  z-index: $header-container-z-index;

  .img {
    &.ltr {
      float: left;
    }
  }

  &.is-visible {
    visibility: visible;
  }

  &.fullscreen {
    position: absolute;
  }

  button {

    @include default-button;

    @include subtitle-font-size;

    font-size: 1.25vw;
    font-style: normal;
    font-weight: 500;
    line-height: vw(35px);
    position: relative;
    text-align: center;
  }

  :global(.triState) button {
    &::before {

      @include pseudo;

      border-bottom: solid-border();
      height: 70%;
      width: size-without-border(10px);
    }

    &.button-focused::before {
      border-bottom: solid-border(var(--accent));
    }
  }
}

.button-focused {
  color: var(--accent);

  img {
    border: solid-border(var(--accent));
  }
}
