.bar::-webkit-scrollbar {
  width: 0px;
}

.bar::-webkit-scrollbar-track {
  background: #f1f1f1;
  opacity: 0;
}

.bar::-webkit-scrollbar-thumb {
  background: #888;
  width: 6px;
}

.bar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.bar::-webkit-scrollbar-track-piece {
   display:none;
}
